import * as React from 'react';
import '../../styles/global.scss';
import '../../styles/normalize.scss';
import '../../styles/typography.scss';
import '../../styles/settings.scss';
import RootLayout from '../../components/RootLayout/RootLayout';
import Headers from '../../components/Headers/Headers';
import BlogArticleHosting from '../../components/BlogArticles/BlogArticleHosting';
import Footer from '../../components/Footer/Footer';
import ScroolUpButton from '../../components/ScroolUpButton/ScroolUpButton';

const BlogHosting = () => {
  const title_top = 'How to Choose the Best';
  const title_middle = 'Hosting and Domain';
  const siteTitle = 'How to Choose the Best Hosting and Domain - Nowoczesna witryna';
  const siteDescription =
    'How to choose the best hosting and domain - This article will help you make the right choice, as these are essential aspects that should not be overlooked...';
  const siteUrl = 'https://www.nowoczesnawitryna.com/blog/how-to-choose-the-best-hosting-and-domain/';
  const siteUrlOtherLang = 'https://www.nowoczesnawitryna.pl/blog/jak-wybrac-najlepszy-hosting-i-domene/';

  return (
    <>
      <RootLayout title={siteTitle} url={siteUrl} urlOtherLang={siteUrlOtherLang} description={siteDescription} />
      <Headers title_top={title_top} title_middle={title_middle} active="blog" />
      <ScroolUpButton />
      <BlogArticleHosting />
      <Footer />
    </>
  );
};

export default BlogHosting;
